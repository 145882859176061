import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import './IssuesReceived.css'
import configData from '../../config/config.json'
import Messages from './Messages'
import PostIcon from '@material-ui/icons/LocalPostOfficeRounded'

function IssuesReceived() {
  const [IssuesReceived, setIssuesReceived] = useState([])
  console.log('fromapiii>>', IssuesReceived)
  useEffect(() => {
    var token = localStorage.getItem('auth_token')
    console.log('from issues received')
    const headers = {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    axios({
      method: 'post',
      url: `${configData.BASE_URL}/messages/for_user`,
      data: [],
      headers: headers,
    })
      .then((res) => {
        let issues = res.data
        setIssuesReceived(issues)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <div className='issuesReceived'>
      <nav className='issuesReceived__nav'>
        <Link to='/user-dashboard' className='issuesReceived__link'>
          <ChevronLeftIcon className='issuesReceived__icon' /> Messages
        </Link>
      </nav>
      <div className='issuesReceived__body'>
        {IssuesReceived.length > 0 || IssuesReceived.length !== 0 ? (
          IssuesReceived.map((issues, index) => (
            <Messages
              issue={issues.message_body}
              key={index}
              date={issues.created_at}
            />
          ))
        ) : (
          <>
            <div className='no_message' style={{ textAlign: 'center', margin: '10% auto' }}>
              <PostIcon style={{ fontSize: '2rem', color: '#161521' }} />
              <p style={{ fontSize: '2rem', color: '#8c91a8' }}>
              No messages yet. You are  a responsible citizen. Keep going :)
              </p>
            </div>
          </>
        )}
      </div>
    </div>
    // {IssuesReceived.map((issues, index) => (
    //   <Messages
    //     issue={issues.message_body}
    //     key={index}
    //     date={issues.created_at}
    //   />
    // ))}
  )
}

export default IssuesReceived
