import React from 'react'
import './App.css'
import Home from './Pages/Home/Home'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import SignUp from './Pages/SignUp/SignUp'
import SignIn from './Pages/SignIn/SignIn'
import SignUpSuccess from './Pages/SignUp/SignUpSuccess/SignUpSuccess'
import SignInSuccess from './Pages/SignIn/SignInOtp/SignInSuccess/SignInSuccess'
import Dashboard from './Pages/Dashborad/Dashboard'
import CreateTagForm from './Pages/CreateTag/CreateTagForm'
import CreateTagSuccess from './Pages/CreateTag/CreateTagSuccess/CreateTagSuccess'
import ViewQr from './Pages/CreateTag/CreateTagSuccess/ViewQr'
import RaiseIssue from './Pages/RaiseIssue/RaiseIssue'
import RaiseIssueSuccess from './Pages/RaiseIssue/RaiseIssueSuccess/RaiseIssueSuccess'
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy'
import ActivateTag from './Pages/ActivateTag/ActivateTag'
import ActivateTagUser from './Pages/ActivateTag/ActivateTagUser'
// import RaiseIssuesUser from './Pages/RaiseIssueUpdated/RaiseIssuesUser'
import ActivationSuccess from './Pages/ActivateTag/ActivationSuccess'
import ActivationSuccessUser from './Pages/ActivateTag/ActivationSuccessUser'
import IssuesReceived from './Pages/IssuesReceived/IssuesReceived'

function App() {
  return (
    <Router>
      <div className='app'>
        <Switch>
          <Route path='/signup' component={SignUp} />
          <Route path='/signup-success' component={SignUpSuccess} />
          <Route path='/user-dashboard' component={Dashboard} />
          <Route path='/create-tag' component={CreateTagForm} />
          <Route path='/create-success' component={CreateTagSuccess} />
          <Route path='/view-qr' component={ViewQr} />
          <Route path='/messages' exact component={RaiseIssue} />
          {/* <Route path='/raise-issue' exact component={RaiseIssuesUser} /> */}
          <Route path='/issues-received' exact component={IssuesReceived}/>
          <Route path='/signup-and-activate-qr' exact component={ActivateTag} />
          <Route path='/activate-qr' exact component={ActivateTagUser} />
          <Route path='/raise-issue-success' component={RaiseIssueSuccess} />
          <Route path='/signin' component={SignIn} />
          <Route path='/signin-success' component={SignInSuccess} />
          <Route path='/activation-success' component={ActivationSuccess} />
          <Route path='/activation-success-user' component={ActivationSuccessUser} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/' exact component={Home} />
        </Switch>
      </div>
    </Router>
  )
}

export default App
