import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import SignInOtp from './SignInOtp/SignInOtp'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import './SignIn.css'
import configData from '../../config/config.json'
import Footer from '../../Components/Footer'

function SignIn() {
  const [email, setEmail] = useState('')
  const [showOtp, setShowOtp] = useState(false)
  const [error, setError] = useState(false)
  const isInvalid = email === ''

  const signinHandler = async (e) => {
    e.preventDefault()
    console.log('signHandler')

    const headers = {
      'Content-type': 'application/json',
    }
    await axios(
      {
        method: 'post',
        url: `${configData.BASE_URL}/users/send_login_otp`,
        data: {
          login: email,
        },
      },
      { headers }
    )
      .then((res) => {
        setShowOtp(true)
        console.log(res)
      })
      .catch((err) => {
        setError(true)
        console.log(err)
      })
  }

  return (
    <div className='signIn'>
      {/* section1 */}
      <nav className='signIn__nav'>
        <Link to='/' className='signIn__link'>
          <ChevronLeftIcon className='signIn__icon' /> Log In
        </Link>
      </nav>
      {/* section2 */}
      <div className='container'>
        <p>
          Enter your mobile number to sign in.{' '}
          <Link to='/signup' className='signUp__link'>
            Sign Up
          </Link>{' '}
          <br></br> if you don't have an account
        </p>
        <form
          action=''
          method='post'
          className='signIn__form'
          onSubmit={signinHandler}
        >
          <div className='form-control'>
            {error && (
              <p className='error'>
                No account registered with this Phone No.
              </p>
            )}
            <input
              type='text'
              name='email'
              id='email'
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              placeholder='Enter your Phone No.'
            />
          </div>
          <div className='form-control'>
            {showOtp ? null : (
              <button
                type='submit'
                className='form__otp'
                style={{ visibility: isInvalid ? 'hidden' : 'visible' }}
              >
                Get OTP
              </button>
            )}
          </div>
        </form>
        {showOtp ? <SignInOtp email={email} /> : null}
      </div>
      <Footer />
    </div>
  )
}

export default SignIn
