import React from 'react'
import './PrivacyPolicy.css'
function PrivacyPolicy() {
  return (
      <>
      <div className='privacy_header'>
          <div className="privacy_container">

        <h1>Terms of Service & Privacy Policy</h1>
        <p>
          www.getmetag.com ("getmetag" "our," "we," or "us") provides Services to 
          you under the following Terms of Service and Privacy Policy.
          
        </p>
          </div>
      </div>
 <div className='privacy_container'>
      <div className='terms_ofservice'>
        <h2>Terms of Service</h2>
        <h3>About Our Services</h3>
        <p>
          {' '}
          <strong>Creating a unique QR code: </strong> A registered user of
          getmetag can create a unique QR code by providing certain information
          on www.getmetag.com. This QR code will act as the gateway for public
          [Unregistered users of getmetag] to contact the registered user.
        </p>
        <p>
          {' '}
          <strong>Connecting public to getmetag users: </strong> We bridge a
          communication channel between public [Unregistered users of getmetag]
          and registered users of getmetag. This channel can be used for sending information as text messages and/or placing automated calls
          which will also be viewed by getmetag admin 
        </p>
        <p>
          {' '}
          <strong>NO ACCESS TO EMERGENCY SERVICES: </strong> There are important
          differences between our Services and your mobile phone and a
          fixed-line telephone and SMS services. Our Services do not provide
          access to emergency services or emergency services providers,
          including the police, fire departments, or hospitals, or otherwise
          connect to public safety answering points. You should ensure you can
          contact your relevant emergency services providers through a mobile
          phone, a fixed-line telephone, or other service.
        </p>
        <p>
          {' '}
          <strong>SAFTEY PRACTICES: </strong> We or Our Services do not cover the risk of any 
          incidents that may occur when the public and the getmetag owner physically meet to resolve 
          an issue. You must ensure that you take necessary safety precautions during a physical meeting 
          that has happened due to scanning of a getmetag and attempting to resolve an issue
        </p>
      </div>
      <div className='privacy_policy'>
        <h2>Privacy Policy</h2>
        <p>
          Our Privacy Policy ("Privacy Policy") helps explain our data
          practices, including the information we process to provide our
          Services.
        </p>
        <h3>Information We Collect </h3>
        <p>
          We require certain information to deliver our Services and without
          this we will not be able to provide our Services to you.
        </p>
        <h4>Information You Provide</h4>
        <p>
          To create an account in getmetag, you must provide your mobile phone
          number and basic information (including a profile name of your choice)
          To create your getmetag QR Code, you must provide your vehicle
          registration number and postal address. To raise an issue and/or place an automated call by scanning a
          getmetag QR Code, you must provide your mobile phone number
        </p>
        <h4>Automatically collected Information</h4>
        <p>
          <strong> Usage And Log Information. </strong>We collect information
          about your activity on our Services, like service-related and
          diagnostic information. This includes information about your activity
          (including how you use our Services, your Services settings, log
          files, and diagnostic, crash, website logs and reports. This also
          includes information about when you registered to use our Services
        </p>

        <p>
          <strong>Cookies.</strong> We use cookies to operate and provide our
          Services, to improve your experiences, understand how our Services are
          being used, and customize them.{' '}
        </p>
        <h3>How we use information</h3>
        <p>
          We use information we have to operate and provide our Services,
          including providing customer support. We use your information to
          contact you when you avail our Services like creating a getmetag QR
          Code, scanning a getmetag QR code and raising an issue and/or placing an automated call, during those
          actions or later so as to fulfill the service. We also use information
          we have to understand how people use our Services; evaluate and
          improve our Services; research, develop, and test new services and
          features; and conduct troubleshooting activities. By using any service offered by getmetag.com, 
          you agree to by pass the DND services that you might have enabled on your mobile number, for getmetag team or 
          thier representative or any delegated service to either call you or message you. 
        </p>
      </div>
    </div>
    </>
  )
}

export default PrivacyPolicy
