import React, { useState } from 'react';
import { useHistory,Link } from 'react-router-dom';
import axios from 'axios';
import './OtpForm.css';
import configData from '../../../config/config.json';

const OtpForm = ({ email }) => {
  const history = useHistory();
  const mailId = email;

  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const handleSubmit = async (e) => {
    console.log('insideOtpForm.js');
    e.preventDefault();
    const headers = {
      'Content-Type': 'application/json',
    };
    await axios(
      {
        method: 'post',
        url: `${configData.BASE_URL}/users/verify_login_otp`,
        data: {
          login: mailId,
          otp: otp,
        },
      },
      { headers }
    )
      .then((res) => {
        console.log(res);
        const TOKEN = res.data.auth_token;
        localStorage.setItem('auth_token', TOKEN);
        history.push('/signup-success');
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setOtp('');
      });
  };

  return (
    <form className="Signup otpform" method="post" onSubmit={handleSubmit}>
      <div className="form-control">
        {error ? <p className="error">Invalid otp</p> : null}
        <input
          type="text"
          id="otp"
          name="otp"
          onChange={({ target }) => setOtp(target.value)}
          value={otp}
          placeholder="enter 4 digit OTP"
        />
      </div>
      <p className="text__otp">
        By clicking Sign Up,<br></br> you accept our{' '}
        <span style={{ color: '#004AFF', cursor: 'pointer' }}>
          {' '}
          <Link to='/privacy-policy' className="policy__link">

          T&amp;C &amp; Privacy policy
          </Link>
        </span>
      </p>
      <div className="form-control">
        <button type="submit" className="form__otp">
          Sign Up{' '}
        </button>
      </div>
    </form>
  );
};

export default OtpForm;
