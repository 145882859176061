import React, { useEffect } from 'react'
import './Home.css'
import { Link } from 'react-router-dom'
import logo from '../../Assets/textlogo.svg'
import Footer from '../../Components/Footer'

function Home() {
  useEffect(() => {
    document.title = 'Home - Getmetag'
  }, [])
  return (
    <div>
      <header className='home__header'>
        <div className='home__Wrapper'>
          <div className='header__logo'>
            <img src={logo} alt='issueraised' width='226px' height='35px' />
          </div>
          <div className='brand__text'>
            <h1 className='home__title'>Phone No.</h1>
            <h1 className='home__title'>Hidden</h1>
            <h1 className='home__title'>
              QR Stickers
            </h1>
            <p className='home_titleDec'>Stick Getmetag | Park Confidently | No App</p>
          </div>
          <div className='home__navigation'>
            <Link to='/signup' className='home__nav'>
              <div className='home__signUpText'>
                <h6>Order Now</h6>
              </div>
            </Link>
            <Link to='/signin' className='home__nav'>
              <div className='home__signInText'>
                <h6>Log In</h6>
              </div>
            </Link>
          </div>
        </div>
      </header>
      <section className='home__productDes'>
        <div className='home__Wrapper'>
          <p className='brand__des'>
            getmetag is an innovative and unique QR code designed to offer an efficient solution 
          for instantly connecting the public to the tag owner, with out exposing the contact number of both the parties. 
          Yes, you heard it right !
          </p>
           <p className='brand__des'>
          getmetag can help when there is Parking and Traffic Incident - In crowded parking lots or when a car is causing an obstruction, 
          concerned individuals can scan the getmetag to alert the owner about the situation. This facilitates a smooth and quick resolution, 
          ensuring that the vehicle owner can be reached without compromising privacy.</p>
          <h3 className='home__productDesHeader'>Why own a getmetag?</h3>
          <div className='home__productDesItems'>
            <div className='home__productDesItems--3'>
              <svg
                width='44'
                height='44'
                viewBox='0 0 44 44'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M34.251 4.52632C25.4456 4.52632 19.3986 0 15.155 0C10.9115 0 2 2.42105 2 21.7895C2 41.1579 13.988 44 18.6559 44C40.5102 44 49.8461 4.52632 34.251 4.52632Z'
                  fill='#F2F3F7'
                />
                <path
                  d='M5 30H10.5L14.5 33L15 30H15.5L17.5 29L19 27V25L18 22.5L15.5 21H4.5L2.5 21.5L1 23.5V25.5L2 28.5L5 30Z'
                  fill='white'
                />
                <path
                  d='M14.1694 34.7862C14.2581 34.8896 14.3764 34.9634 14.5083 34.9976C14.6402 35.0318 14.7794 35.0248 14.9072 34.9775C15.035 34.9302 15.1453 34.8449 15.2231 34.733C15.301 34.6212 15.3428 34.4882 15.3428 34.3519V30.9752C16.6904 30.8064 17.923 30.1302 18.7895 29.0842C19.6559 28.0383 20.0909 26.7013 20.0058 25.3458C19.9208 23.9902 19.3221 22.7181 18.3317 21.7887C17.3414 20.8592 16.0339 20.3423 14.6757 20.3433H5.33661C3.92126 20.3433 2.56387 20.9056 1.56306 21.9064C0.562249 22.9072 0 24.2646 0 25.6799C0 27.0953 0.562249 28.4527 1.56306 29.4535C2.56387 30.4543 3.92126 31.0166 5.33661 31.0166H10.9401L14.1694 34.7862ZM1.33415 25.6799C1.33521 24.6188 1.75724 23.6013 2.50762 22.851C3.25799 22.1006 4.27542 21.6785 5.33661 21.6775H14.6757C15.7372 21.6775 16.7552 22.0992 17.5059 22.8498C18.2565 23.6004 18.6781 24.6184 18.6781 25.6799C18.6781 26.7415 18.2565 27.7595 17.5059 28.5101C16.7552 29.2607 15.7372 29.6824 14.6757 29.6824C14.4988 29.6824 14.3291 29.7527 14.204 29.8778C14.0789 30.0029 14.0086 30.1726 14.0086 30.3495V32.5508L11.7512 29.9172C11.6888 29.8438 11.6112 29.7848 11.5238 29.7443C11.4364 29.7038 11.3413 29.6827 11.2449 29.6824H5.33661C4.27542 29.6813 3.25799 29.2593 2.50762 28.5089C1.75724 27.7586 1.33521 26.7411 1.33415 25.6799Z'
                  fill='#161521'
                />
                <path
                  d='M4.00244 24.3458C4.00244 23.9774 4.3011 23.6787 4.66952 23.6787H15.3427C15.7112 23.6787 16.0098 23.9774 16.0098 24.3458C16.0098 24.7142 15.7112 25.0129 15.3427 25.0129H4.66952C4.3011 25.0129 4.00244 24.7142 4.00244 24.3458Z'
                  fill='#161521'
                />
                <path
                  d='M4.00244 27.0141C4.00244 26.6457 4.3011 26.347 4.66952 26.347H12.6744C13.0428 26.347 13.3415 26.6457 13.3415 27.0141C13.3415 27.3825 13.0428 27.6812 12.6744 27.6812H4.66952C4.3011 27.6812 4.00244 27.3825 4.00244 27.0141Z'
                  fill='#161521'
                />
                <path
                  d='M14.6753 27.0141C14.6753 26.6457 14.974 26.347 15.3424 26.347C15.7108 26.347 16.0094 26.6457 16.0094 27.0141C16.0094 27.3825 15.7108 27.6812 15.3424 27.6812C14.974 27.6812 14.6753 27.3825 14.6753 27.0141Z'
                  fill='#161521'
                />
                <path
                  d='M39 19H33.5L29.5 22L29 19H28.5L26.5 18L25 16V14L26 11.5L28.5 10H39.5L41.5 10.5L43 12.5V14.5L42 17.5L39 19Z'
                  fill='white'
                />
                <path
                  d='M38.6631 9.0103H29.324C27.9658 9.00929 26.6583 9.52617 25.668 10.4556C24.6776 11.3851 24.0789 12.6572 23.9939 14.0127C23.9088 15.3683 24.3438 16.7052 25.2102 17.7512C26.0766 18.7971 27.3092 19.4734 28.6569 19.6422V23.0189C28.6569 23.1552 28.6987 23.2882 28.7765 23.4C28.8544 23.5118 28.9647 23.5971 29.0925 23.6444C29.2203 23.6917 29.3595 23.6988 29.4914 23.6646C29.6233 23.6304 29.7416 23.5566 29.8303 23.4532L33.0596 19.6835H38.6631C40.0784 19.6835 41.4358 19.1213 42.4366 18.1205C43.4374 17.1197 43.9997 15.7623 43.9997 14.3469C43.9997 12.9316 43.4374 11.5742 42.4366 10.5734C41.4358 9.57255 40.0784 9.0103 38.6631 9.0103ZM38.6631 18.3494H32.7548C32.6586 18.3494 32.5635 18.3702 32.4761 18.4104C32.3887 18.4506 32.3111 18.5092 32.2485 18.5822L29.9911 21.2178V19.0164C29.9911 18.8395 29.9208 18.6698 29.7957 18.5447C29.6706 18.4196 29.5009 18.3494 29.324 18.3494C28.2625 18.3494 27.2444 17.9277 26.4938 17.1771C25.7432 16.4265 25.3215 15.4084 25.3215 14.3469C25.3215 13.2854 25.7432 12.2673 26.4938 11.5167C27.2444 10.7661 28.2625 10.3445 29.324 10.3445H38.6631C39.7246 10.3445 40.7426 10.7661 41.4932 11.5167C42.2438 12.2673 42.6655 13.2854 42.6655 14.3469C42.6655 15.4084 42.2438 16.4265 41.4932 17.1771C40.7426 17.9277 39.7246 18.3494 38.6631 18.3494Z'
                  fill='#161521'
                />
                <path
                  d='M31.9922 13.0128C31.9922 12.6443 32.2908 12.3457 32.6593 12.3457H39.33C39.6984 12.3457 39.9971 12.6443 39.9971 13.0128C39.9971 13.3812 39.6984 13.6798 39.33 13.6798H32.6593C32.2908 13.6798 31.9922 13.3812 31.9922 13.0128Z'
                  fill='#161521'
                />
                <path
                  d='M27.9897 15.6811C27.9897 15.3126 28.2884 15.014 28.6568 15.014H39.33C39.6985 15.014 39.9971 15.3126 39.9971 15.6811C39.9971 16.0495 39.6985 16.3481 39.33 16.3481H28.6568C28.2884 16.3481 27.9897 16.0495 27.9897 15.6811Z'
                  fill='#161521'
                />
                <path
                  d='M27.9897 13.0128C27.9897 12.6443 28.2884 12.3457 28.6568 12.3457H29.991C30.3594 12.3457 30.6581 12.6443 30.6581 13.0128C30.6581 13.3812 30.3594 13.6798 29.991 13.6798H28.6568C28.2884 13.6798 27.9897 13.3812 27.9897 13.0128Z'
                  fill='#161521'
                />
              </svg>
              <p>
               Sign Up & Order your getmetag QR Code
              </p>
               <p>
               Visibily stick the getmetag on your four wheeler
              </p>
            </div>
            <div className='home__productDesItems--4'>
              <svg
                width='40'
                height='44'
                viewBox='0 0 40 44'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M32.25 4.52632C23.4446 4.52632 17.3976 0 13.154 0C8.91048 0 -0.000976562 2.42105 -0.000976562 21.7895C-0.000976562 41.1579 11.9871 44 16.655 44C38.5093 44 47.8451 4.52632 32.25 4.52632Z'
                  fill='#F2F3F7'
                />
                <g clipPath='url(#clip0)'>
                  <path
                    d='M32.9998 12.5C30.5998 12.1 23.3332 8.66667 19.9998 7C16.3997 9 9.8331 11.5 6.99983 12.5V16.5C6.83316 19.3333 7.39983 26.1 10.9998 30.5C14.5998 34.9 18.4998 36.6667 19.9998 37C20.8332 37.1667 23.3998 36.6 26.9998 33C31.4998 28.5 31.9998 24.5 32.4998 23C32.8998 21.8 32.9998 15.5 32.9998 12.5Z'
                    fill='white'
                  />
                  <path
                    d='M33.1495 11.5257C28.7467 10.3193 24.3181 8.44086 20.3423 6.09348C20.1311 5.9688 19.869 5.9688 19.6579 6.09348C15.5674 8.50829 11.378 10.2851 6.85023 11.5257C6.55773 11.6057 6.35498 11.8716 6.35498 12.1749V19.0528C6.35498 26.1354 9.62241 30.8237 12.3635 33.5094C15.3145 36.4009 18.752 38 20.0001 38C21.248 38 24.6855 36.4009 27.6365 33.5094C30.3776 30.8237 33.6448 26.1355 33.6448 19.0528V12.1748C33.6448 11.8716 33.442 11.6057 33.1495 11.5257ZM32.2986 19.0527C32.2986 25.6679 29.251 30.0426 26.6943 32.5477C23.7923 35.3911 20.6896 36.6536 20.0001 36.6536C19.3105 36.6536 16.2076 35.3911 13.3057 32.5477C10.749 30.0425 7.70129 25.6679 7.70129 19.0527V12.6858C12.0312 11.4565 16.0635 9.74079 20.0002 7.45311C23.8386 9.6801 28.0721 11.4811 32.2986 12.6856V19.0527Z'
                    fill='#161521'
                  />
                  <path
                    d='M17.7951 21.3497C17.5965 21.154 17.3323 21.0464 17.0514 21.0464C16.7704 21.0464 16.5062 21.1541 16.3075 21.3497C15.8975 21.7538 15.8975 22.4112 16.3075 22.8152L18.2176 24.6967C18.4162 24.8923 18.6804 25 18.9613 25C19.2423 25 19.5065 24.8923 19.7051 24.6966L23.6925 20.7687C24.1025 20.3646 24.1025 19.7073 23.6924 19.3034C23.4938 19.1077 23.2296 19 22.9486 19C22.6677 19 22.4035 19.1077 22.2048 19.3034L18.9613 22.4985L17.7951 21.3497Z'
                    fill='#161521'
                  />
                  <path
                    d='M14.0337 12.6105C13.8974 12.2646 13.5066 12.0946 13.1606 12.231C11.9581 12.7048 10.7239 13.1409 9.49219 13.5273C9.21151 13.6154 9.02051 13.8754 9.02051 14.1695V16.7689C9.02051 17.1407 9.32194 17.4421 9.69363 17.4421C10.0653 17.4421 10.3668 17.1407 10.3668 16.7689V14.6614C11.4717 14.3039 12.5754 13.9085 13.6541 13.4834C14.0001 13.3473 14.17 12.9565 14.0337 12.6105Z'
                    fill='#161521'
                  />
                  <path
                    d='M15.3124 12.7382C15.4027 12.7382 15.4944 12.72 15.5823 12.6816L15.5947 12.6763C15.9352 12.527 16.0886 12.1306 15.9394 11.7901C15.79 11.4496 15.3913 11.2955 15.0511 11.4446L15.0402 11.4493C14.6995 11.5981 14.5456 11.9943 14.6944 12.3349C14.8049 12.5878 15.0527 12.7382 15.3124 12.7382Z'
                    fill='#161521'
                  />
                  <path
                    d='M28.02 27.7051C27.7089 27.5018 27.2918 27.5893 27.0882 27.9005C26.5335 28.7494 25.8828 29.5624 25.1538 30.3166C24.5543 30.9368 23.9046 31.5148 23.223 32.0344C22.9274 32.2598 22.8705 32.6822 23.0958 32.9779C23.2284 33.1516 23.4288 33.2429 23.6316 33.2429C23.774 33.2429 23.9174 33.198 24.0393 33.105C24.7746 32.5443 25.4752 31.921 26.1216 31.2524C26.9098 30.4371 27.6141 29.5571 28.2153 28.6368C28.4187 28.3256 28.3311 27.9085 28.02 27.7051Z'
                    fill='#161521'
                  />
                  <path
                    d='M21.6598 33.0901L21.6212 33.1128C21.2995 33.2991 21.1896 33.7109 21.3758 34.0326C21.5006 34.2482 21.7266 34.3686 21.959 34.3686C22.0734 34.3686 22.1895 34.3394 22.2955 34.2778L22.3401 34.2517C22.6609 34.0638 22.7687 33.6515 22.5808 33.3306C22.3928 33.01 21.9804 32.9024 21.6598 33.0901Z'
                    fill='#161521'
                  />
                </g>
                <defs>
                  <clipPath id='clip0'>
                    <rect
                      width='32'
                      height='32'
                      fill='white'
                      transform='translate(4 6)'
                    />
                  </clipPath>
                </defs>
              </svg>

              <p>
                Public* can scan your getmetag to instantly alert you in case of an issue with your vehicle 
              </p>
              <p>
             Contactnumbers not exposed for both the parties. No spams
              </p>
              <p>
             No App Installations. Everything is on your phone internet browser
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Home
