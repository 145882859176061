import React from 'react';
import { Link } from 'react-router-dom';
import './SignUpSuccess.css';
import Success from '../../../Assets/success.svg';
const SignUpSuccess = () => {
  return (
    <div className="signUp__success">
      <div className="signUp__sign">
        <img src={Success} alt="success-sign" />
      </div>
      <div className="signUp__text">
        <p>Congratulations!</p>
        <h2>You now have a getmetag account</h2>
        <p>
          Proceed to place an order for getmetag, view history and more
        </p>
      </div>
      <Link to="/user-dashboard" className="SignUp__link">
        <div className="signUp__done">Proceed</div>
      </Link>
    </div>
  );
};

export default SignUpSuccess;
