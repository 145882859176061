import React, { useState, useEffect } from 'react'
import './ViewQr.css'
import axios from 'axios'
import configData from '../../../config/config.json'
function ViewQr() {
  const [qr, setQR] = useState('')
  const [qrUrl, setQrUrl] = useState('')
  const [qrCode, setQrCode] = useState([])
  console.log(qr)
  useEffect(() => {
    var token = localStorage.getItem('auth_token')
    const headers = {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    axios({
      method: 'get',
      url: `${configData.BASE_URL}/qr_assets`,
      headers: headers,
    })
      .then((res) => {
        console.log('qr_URL',res)
        const qr_data = res.data.payload
        setQrCode(
          qr_data.map((vehicle) => {
            return vehicle.qr_url
          })
        )
        // console.log('from response', qr_data)
        console.log('from app state', qrCode)
        const qr_asset = res.data.payload[0].qr_url
        setQR(qr_asset)
      })
      .catch((err) => console.log(err))
  }, [qrUrl,qrCode])

  const handleViewQR = async (e) => {
    var token = localStorage.getItem('auth_token')
    console.log(token)
    e.preventDefault()
    const headers = {
      'Content-Transfer-Encoding': 'binary',
      'Content-Type': 'image/png',
      Authorization: 'Bearer ' + token,
    }
    // fetch(`https://sandbox-api.getmetag.com${qr}`, {
    fetch(`${configData.BASE_URL}${qr}`, {
      credentials: 'same-origin',
      headers: headers,
    })
      .then((data) => {
        console.log('inside first then block')
        return data.blob()
      })
      .then((img) => {
        var dd = URL.createObjectURL(img)
        console.log('dd', dd)
        setQrUrl(dd)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <div className='viewQr'>
      <small>this page is for devleopment only</small>
      <button onClick={handleViewQR} className='CreateTagSuccess__qr'>
        View QR code
      </button>

      <img src={qrUrl} alt='qr_code' />
    </div>
  )
}

export default ViewQr
