import React from 'react'
import jwt_decode from 'jwt-decode'
import './Dashboard.css'
import { Link } from 'react-router-dom'
import RightArrow from '../../Assets/rightarrow.svg'
import logo from '../../Assets/get.svg'
import IssueRaised from '../../Assets/IssueRaised.svg'
import IssueReceived from '../../Assets/IssueReceived.svg'
import { Button } from 'react-bootstrap'

const Dashboard = ({ history }) => {
  var token = localStorage.getItem('auth_token')
  var decoded = jwt_decode(token)
  var username = decoded.first_name

  localStorage.setItem('username', username)

  const logOut = () => {
    if (token) {
      localStorage.removeItem('auth_token')
      history.push('/signin')
    }
  }

  return (
    <div className='dashboard'>
      <div className='dashboard-header'>
        <div className='container'>
          <Button variant='light' onClick={logOut}>
            LOGOUT
          </Button>
          <h1>Hi,</h1>
          <p className='username'>{username}</p>
        </div>
      </div>
      <div className='dashboard-body'>
        <div className='container'>
          <Link to='/create-tag' className='create-tag-link'>
            <div className='section-1'>
              <img src={RightArrow} alt='arrow' />
              <p className='heading'>Order getmetag</p>
              <p className='section-desc'>Place your order here</p>
            </div>
          </Link>
          <Link to='/issues-received' className='create-tag-link'>
            <div className='section-3'>
              <img src={IssueReceived} alt='image3' />
              <p className='heading'>Calls Received</p>
              <div className='coming-soon'>
                <p className='section-desc'>Incoming Call Log</p>
              </div>
            </div>
          </Link>
          <div className='section-2'>
            <img src={logo} alt='image1' />
            <p className='heading'>Order Status</p>
            <div className='coming-soon'>
              <p className='section-desc'>Reach out on Instagram @getmetagqr</p>
              <div className='loader'>
                <div className='circle'>.</div>
                <div className='circle'>.</div>
                <div className='circle'>.</div>
              </div>
            </div>
          </div>

          <div className='section-4'>
            <img src={IssueRaised} alt='image2' />
            <p className='heading'>Your Tags</p>
            <div className='coming-soon'>
              <p className='section-desc'>Coming soon</p>
              <div className='loader'>
                <div className='circle'>.</div>
                <div className='circle'>.</div>
                <div className='circle'>.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
