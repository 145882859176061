import React, { useState } from 'react'
import { useFormik } from 'formik'
// import { useHistory } from 'react-router-dom';
import './RaiseIssue.css'
import RaiseIssueOtp from './RaiseIssueOtp/RaiseIssueOtp'
import axios from 'axios'
import Spinner from '../../Components/Loader/Spinner'
import configData from '../../config/config.json'

function RaiseIssue({ location }) {
  console.log(location)
  const search = window.location.search
  const params = new URLSearchParams(search)
  const qr_asset_id = params.get('qr_asset_id')
  let vehicleno = params.get('vehicle_no')
  const [otpForm, setOtpForm] = useState(false)
  const [id, setId] = useState('')
  const initialValues = {
    name: '',
    issue: '',
    emailorphone: '',
  }
  const onSubmit = async (values) => {
    await axios({
      method: 'post',
      url: `${configData.BASE_URL}/messages`,
      data: {
        message: {
          qr_asset_id: qr_asset_id,
          sender_name: values.name,
          sender_contact: values.emailorphone,
          message_body: values.issue,
          trigger_phone_call: true
        },
      },
    })
      .then(({ data }) => {
        var messageId = data.payload.id
        setId(messageId)
        console.log('FROM RAISEISSUE>>', messageId)
        setOtpForm(true)
      })
      .catch((err) => console.log(err))
  }
  const validate = (values) => {
    let errors = {}
    if (!values.name) {
      errors.name = 'Name required'
    }
   // if (!values.issue) {
     // errors.issue = 'Enter the problem you face'
   // }
    if (!values.emailorphone) {
      errors.emailorphone = 'Phone No. required'
    } 
    return errors
  }
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })
  const isInvalid =
    formik.values.name === '' ||
    formik.values.emailorphone === ''

  return (
    <div className='raiseIssue'>
      <div className='raiseIssue__header'>
        <div className='container'>
          <p style={{ paddingBottom: '10px' }}>You scanned</p>
          <p className='vehicle_no'>{vehicleno ? vehicleno : <Spinner />}</p>
          {/* <Spinner/> */}
          <h1 style={{ paddingBottom: '10px' }}>getmetag</h1>
          <p style={{ paddingBottom: '10px', fontWeight: '100' }}>
            Call Tag Owner
          </p>
        </div>
      </div>
      <div className='raiseIssue__body'>
        <div className='container'>
          <p className='raiseIssue__text'>
            To call vehicle owner, enter your name & mobile no.
            <br></br>This info WILL NOT BE SHARED with the owner.
          </p>
          <form
            action=''
            method='post'
            className='raiseIssue__form'
            onSubmit={formik.handleSubmit}
          >
            
            <div className='form-control'>
              <input
                type='text'
                name='name'
                id='name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                placeholder='Enter your name'
              />
              {formik.touched.name && formik.errors.name ? (
                <div className='error'>{formik.errors.name}</div>
              ) : null}
            </div>
            <div className='form-control'>
              <input
                type='text'
                id='emailorphone'
                name='emailorphone'
                onChange={formik.handleChange}
                value={formik.values.emailorphone}
                onBlur={formik.handleBlur}
                placeholder='enter your phone no.'
              />
              {formik.touched.emailorphone && formik.errors.emailorphone ? (
                <div className='error'>{formik.errors.emailorphone}</div>
              ) : null}
            </div>
            <div className='form-control'>
              <small style={{ visibility: isInvalid ? 'hidden' : 'visible' }}>
                You will receive an incoming call after you validate the OTP. Answer the call & wait to connect to owner
              </small>
              {otpForm ? null : (
                <button
                  type='submit'
                  className='form__otp'
                  style={{ visibility: isInvalid ? 'hidden' : 'visible' }}
                >
                  Get OTP
                </button>
              )}
            </div>
          </form>
          {otpForm ? <RaiseIssueOtp messageid={id} /> : null}
        </div>
      </div>
    </div>
  )
}

export default RaiseIssue
