import React from 'react'
import Loader from '../../Assets/loader.gif'
function Spinner() {
  return (
    <div>
      <img
        src={Loader}
        alt='spinner'
        style={{ width: '50px', height: '50px', padding: '0px' }}
      />
    </div>
  )
}

export default Spinner
