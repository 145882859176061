const validation = (values) => {

    let errors={};

 if(!values.name){
        errors.name= "Name is required."
    }
 if(!values.vehicleNo){
        errors.vehicleNo="Vehicle Number is required"
    }
    if(!values.otp){
        errors.otp="OTP is required"
    } else if(values.otp.length !== 4){
      errors.otp="OTP should be 4 digits."
    }
        if(!values.email){
                errors.email="Email is required."
            } else if(!/\S+@\S+\.\S+/.test(values.email)){
                errors.email="Email is invalid."
            }
// if(!values.message){
//     errors.message="Message is required"
// }   
//  if(!values.mail){
//         errors.mail="Email is required."
//     } else if(!/\S+@\S+\.\S+/.test(values.mail)){
//         errors.mail="Email is invalid."
//     }
//  if(!values.companyName){
//         errors.companyName="Company name is required."
//     }
//     if(!values.phoneNo){
//         errors.phoneNo="Phone Number is required."
//     } else if(values.phoneNo.length != 10){
//         errors.phoneNo="Phone Number should be 10 digits."
//     }
//  if(!values.password){
//         errors.password="Password is required."
//     } else if(values.password.length < 5){
//         errors.password ="Password must be more than five characters."
//     } 
//  if(!values.propertyType){
//         errors.propertyType="Select a Property Type"
//     }
//  if(!values.propertyName){
//         errors.propertyName="Enter Property Name"
//     }
//   if(!values.numberOfRooms){
//         errors.numberOfRooms="Enter Number of Rooms"
//     }        
//  if(!values.contactName){
//         errors.contactName="Enter Contact Name"
//     }
//  if(!values.location){
//         errors.location="Enter Location"
//     }
//  if(!values.nonEnglishName){
//         errors.nonEnglishName="Enter Non English Name"
//     }
//  if(!values.languagePreference){
//         errors.languagePreference="Select your Preffered Language"
//     }
//  if(!values.propWebsite){
//         errors.propWebsite="Enter your website URL"
//     } else if(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(values.propWebsite)){
//        errors.propWebsite="Enter a Valid URL"
//     }
//  if(!values.bookingPerMonth){
//         errors.bookingPerMonth="Enter Bookings Per Month"
//     }
//  if(!values.liscenseNo){
//         errors.liscenseNo="Enter Lisence Number"
//     }
//  if(!values.titleName){
//      errors.titleName="Enter Title Name"
//  }
//  if(!values.description){
//     errors.description="Enter Description"
// }    
// if(!values.priceIncludes){
//     errors.priceIncludes="Enter Price Includes"
// }    
// if(!values.priceExcludes){
//     errors.priceExcludes="Enter Price Excludes"
// }    
// if(!values.complimentries){
//     errors.complimentries="Enter Complimentries"
// }    
// if(!values.numberOfGuest){
//     errors.numberOfGuest="Enter Number of Guest"
// }    
// if(!values.numberOfChild){
//     errors.numberOfChild="Enter Number of Child"
// }    
// if(!values.numberOfRoom){
//     errors.numberOfRoom="Enter Number of Room"
// }    
// if(!values.fromDate){
//     errors.fromDate="Select From Date"
// }    
// if(!values.toDate){
//     errors.toDate="Select To Date"
// }    
// if(!values.regularPrice){
//     errors.regularPrice="Enter Regular Price"
// }
// if(!values.offerPrice){
//     errors.offerPrice="Enter Offer Price"
// }       
// if(!values.reason){
//     errors.reason="Please mention the reason"
// }        
// if(!values.message){
//     errors.message="Please provide a message"
// }           
    
    return errors;
}

export default validation
