import React, { useState } from 'react'
import axios from 'axios';
import configData from '../../config/config.json';
import Vector from '../../Assets/Vector.svg'
import "./activate-tag.css"
import { useLocation } from 'react-router'
import logo from '../../Assets/get.svg'
import validation from "../../validation";
import Message from '../../Components/Message';


const ActivateTagUser = ({ history }) => {

   

    const location = useLocation()
   

     //getting asset_id 
      const qr_asset_id =  new URLSearchParams(location.search).get("qr_asset_id")

      console.log(qr_asset_id);
    



    const [errors, setErrors] = useState(false);
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
   


    const [values, setValues ] = useState({
        email : "",
        vehicleNo: "",
        otp: ""
     });

     const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const isInvalid = values.email === '';


    

    
    const handleSubmit = async (e) => {
        let error = 0;
        e.preventDefault()
        setErrors(validation(values)); 


        if(values.email === "" || values.vehicleNo === ""){
            error ++
        } else {
            error = 0
        }

       

        const headers = {
            'Content-type': 'application/json',
          };

          if(error === 0){
          await axios(
            {
              method: 'post',
              url: `${configData.BASE_URL}/qr_assets/send_activation_otp`,
              data: {
                login: values.email,
                qr_asset_id: qr_asset_id,
                vehicle_no: values.vehicleNo,
              },
            },
            { headers }
          )
            .then((res) => {
              console.log(res.status);
              setSuccess(true)
              
            })
            .catch((err) => {
              setErrors(true);
              console.log(err.response.data.errors[0]);
              setErrorMessage(err.response.data.errors[0])
            });
        } else {
            console.log("please fillout the details");
            
        }
    }

    //handle verification

    const handleVerification = async (e) => {
        let error = 0
        e.preventDefault();
        setErrors(validation(values)); 

        if(values.otp === ""){
            error ++
        } else {
            error = 0
        }

        const headers = {
          'Content-type': 'application/json',
        };

        if(error === 0){
        await axios(
          {
            method: 'post',
            url: `${configData.BASE_URL}/qr_assets/verify_activation_otp`,
            data: {
              qr_asset_id: qr_asset_id,
              otp: values.otp,
            },
          },
          { headers }
        )
          .then((res) => {
            localStorage.setItem("expiry-date", res.data.expiry_date)
            history.push("/activation-success-user")
          })
          .catch((err) => {
            console.log(err);
            setErrors(true);
            console.log(err.response.data.errors[0]);
            setErrorMessage(err.response.data.errors[0])
            setValues.otp('');
          });
        }else{
            alert("otp is wrong")
        }
      };


    return (
        <div className="activate-tag-container">
            <div className="activate-tag-top">
               
                  <img src={logo} alt="" className="tag-img" />
               
                
                <h4 className='tag-title-white'>Activate your</h4> <br />
                <h4 className='tag-title-yellow'>getmetag</h4>
            </div>
          
            <div className="activate-tag-sec ">
                <p className='tag-sec-title'>Submit this form to activate your getmetag. Do note that the vehicle no. that your enter cannot be changed later</p>
                {errors && <Message variant='danger'>{errorMessage}</Message>}
                <form >
                <div className="form-control">
                    <input 
                       className='tag-input' 
                       type="text" 
                       placeholder='Enter your vehicle no.' 
                       name='vehicleNo'
                       value={values.vehicleNo}
                       onChange={handleChange}
                    />
                     {errors.vehicleNo && <p className="error">{errors.vehicleNo}</p>}
                </div> 

                <div className="form-control">
                    <input 
                        className='tag-input' 
                        type="email" 
                        name='email'
                        placeholder='Enter registered Phone No.' 
                        value={values.email}
                        onChange={handleChange}
                    />
                   {/* {errors.email && <p className="error">{errors.email}</p>} */}

                </div> 
                {success === true ? null : (
                <button 
                      onClick={handleSubmit}  
                      style={{ visibility: isInvalid ? 'hidden' : 'visible' }}
                      className='activate-btn'> Get OTP 
                      <span className='activate-btn-icon'><img  src={Vector} alt="" /></span>  
                </button>
                )}
                 {success === true ? (  
                        <>
                        <div className="form-control">
                          <input
                            style={{ marginBottom: '40px' }}
                            type="text"
                            id="otp"
                            name="otp"
                            onChange={handleChange}
                            value={values.otp}
                            placeholder="enter 4 digit OTP"
                          />
                        </div>

                        <div className="form-control">
                            <button type="submit" className="form__otp" onClick={handleVerification}>
                            Activate Tag{' '}
                            </button>
                        </div>
                        </>
                    ) :
                     <>
                       <p></p>
                     </>
                    }

                </form>

               
            </div>
        </div>
    )
}

export default ActivateTagUser
