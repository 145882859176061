import React from 'react'
import { Link } from 'react-router-dom'
import '../../SignUp/SignUpSuccess/SignUpSuccess'
import Success from '../../../Assets/success.svg'
const SignUpSuccess = () => {
  return (
    <div className='signUp__success'>
      <div className='signUp__sign'>
        <img src={Success} alt='success-sign' />
      </div>
      <div className='signUp__text'>
        <p>Call Triggered.</p>
        <h2>
          Please pick Up the Incoming Call and wait for the call to connect to owner 
        </h2>
        <p>
          PS: If you are in an EMERGENCY, please do SEEK ALTERNATE help
        </p>
      </div>
      <Link to='/' className='SignUp__link'>
        <div className='signUp__done success'>Done</div>
      </Link>
      <small>
        Liked getmetag ?{' '}
        <Link className='redirect__link' to='/signup'>
          {' '}
          Sign Up{' '}
        </Link>{' '}
        and order for FREE
      </small>
    </div>
  )
}

export default SignUpSuccess
