import React from 'react';

function SignInSuccess() {
  return (
    <div>
      <h1 style={{ fontSize: '30px' }}>hai user</h1>
    </div>
  );
}

export default SignInSuccess;
