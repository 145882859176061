import React from 'react'
import './Messages.css'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
function Messages({ issue, date }) {
  let newDate = date.split('T')
  let time = newDate[1].split('.')[0]

  return (
    <div className='issues'>
      <div className='issues-2'>
        <p>{issue}</p>
        <div className='date'>
          <AccessTimeIcon className='time'/>
          <small>
            {newDate[0]} at {time}
          </small>
        </div>
      </div>
    </div>
  )
}

export default Messages
