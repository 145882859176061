import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <footer className='home__footer'>
      <p>
        Contact us on <span>connect@getmetag.com</span>
      </p>{' '}
      <br />
      <p className='home__footer__last'>
        {' '}
        &copy; {new Date().getFullYear()} getmetag, All Rights Reserved
      </p>
    </footer>
  )
}

export default Footer
