import React from 'react'
import { useHistory } from 'react-router-dom'
import './CreateTagForm.css'
import { useFormik } from 'formik'
import axios from 'axios'
import configData from '../../config/config.json'

function CreateTagForm() {
  const history = useHistory()

  const initialValues = {
    // vehiclenumber: '',
    address1: '',
    address2: '',
    pinnumber: '',
  }

  // CREATE TAG HANDLER
  const createTagHandler = async (values) => {
    var token = localStorage.getItem('auth_token')

    const headers = {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    }
    await axios({
      method: 'post',
      url: `${configData.BASE_URL}/qr_sticker_requests`,
      data: {
        qr_asset: {
          // vehicle_no: values.vehiclenumber,
          address_line_1: values.address1,
          address_line_2: values.address2,
          pincode: values.pinnumber,
        },
      },
      headers: headers,
    })
      .then((res) => {
        console.log('QR STICKER REQ>>>', res)
        history.push({ pathname: '/create-success', state: { data: res.data } })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const validate = (values) => {
    let errors = {}
    // if (!values.vehiclenumber) {
    //   errors.vehiclenumber = 'Please enter your vehicle number';
    // }
    if (!values.address1) {
      errors.address1 = 'Please enter your address'
    }
    if (!values.address2) {
      errors.address2 = 'Please enter your address'
    }
    if (!values.pinnumber) {
      errors.pinnumber = 'Please enter your pincode'
    }
    return errors
  }
  const formik = useFormik({
    initialValues,
    onSubmit: createTagHandler,
    validate,
  })
  return (
    <div className='create__tag'>
      <div className='create__tagHeader'>
        <div className='container'>
          <p>order</p>
          <h2>Your getmetag</h2>
        </div>
      </div>
      <div className='create__tagbody'>
        <div className='container'>
          <p>Order your getmetag @ just Rs 99.00/year incl. shipping charges.</p>
          <p>Our support team will reach out to you to complete payment, after order.</p>
          <p>Enter your full postal address incl. landmark for delivery.</p>
          <form
            action=''
            method='post'
            className='signIn__form'
            onSubmit={formik.handleSubmit}
          >
            {/* //unused code */}

            {/* <div className="form-control">
             
              <input
                type="text"
                name="vehiclenumber"
                id="vehiclenumber"
                value={formik.values.vehiclenumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Enter your vehicle number"
                required
              />
               {formik.touched.vehiclenumber && formik.errors.vehiclenumber ? (
                <div className="error">{formik.errors.vehiclenumber}</div>
              ) : null}
            </div> */}

            <div className='form-control'>
              <input
                type='text'
                name='address1'
                id='address1'
                value={formik.values.address1}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder='Enter address line 1'
                required
              />
              {formik.touched.address1 && formik.errors.address1 ? (
                <div className='error'>{formik.errors.address1}</div>
              ) : null}
            </div>
            <div className='form-control'>
              <input
                type='text'
                name='address2'
                id='address2'
                value={formik.values.address2}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder='Enter address line 2'
                required
              />
              {formik.touched.address2 && formik.errors.address2 ? (
                <div className='error'>{formik.errors.address2}</div>
              ) : null}
            </div>
            <div className='form-control'>
              <input
                type='text'
                name='pinnumber'
                id='pinnumber'
                value={formik.values.pinnumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder='PIN Code'
                required
              />
              {formik.touched.pinnumber && formik.errors.pinnumber ? (
                <div className='error'>{formik.errors.pinnumber}</div>
              ) : null}
            </div>
            <div className='form-control'>
              <button type='submit' className='form__otp'>
                Place Order
              </button>
               <p>..</p>
              <p>Looking for an order already placed? Message us
              on our Instagram @getmetagqr or email to connect@getmetag.com </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreateTagForm
