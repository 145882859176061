import React from 'react'

import { Link } from 'react-router-dom'
import './CreateTagSuccess.css'
import Success from '../../../Assets/success.svg'
const CreateTagSuccess = ({ location }) => {
  const orderId = location.state.data.id
  return (
    <div className='CreateTagSuccess__success'>
      <div className='CreateTagSuccess__sign'>
        <img src={Success} alt='success-sign' />
      </div>
      <div className='CreateTagSuccess__text'>
        <p>Thank You!</p>
        <h2>You have successfully placed the order for your getmetag </h2>
        <h3>Your order ID: {orderId}</h3>
        <p>Please save Order ID</p>
        <p>For Order Status, reach out on our Instagram @getmetagqr with Order ID</p>
      </div>
      <Link to='/user-dashboard' className='CreateTagSuccess__link'>
        <div className='CreateTagSuccess__done'>Proceed to Home</div>
      </Link>
    </div>
  )
}

export default CreateTagSuccess
