import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import configData from '../../../config/config.json';
//import jwt_decode from "jwt-decode";

function SignInOtp({ email }) {
  console.log(email)
  const history = useHistory();
  const [otp, setOtp] = useState('');
  console.log(otp)
  const [error, setError] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {
      'Content-type': 'application/json',
    };
    await axios(
      {
        method: 'post',
        url: `${configData.BASE_URL}/users/verify_login_otp`,
        data: {
          login: email,
          otp: otp,
        },
      },
      { headers }
    )
      .then((res) => {
        const token = res.data.auth_token;
        localStorage.setItem('auth_token', token);
        //console.log('Token from create tag form>>',token);
        //var decoded = jwt_decode(token);
        // const email = decoded.email;
        // var emailName = email.slice(0,email.indexOf("@"));
        // console.log("emailname",emailName);
        history.push('/user-dashboard');
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setOtp('');
      });
  };
  return (
    <form className="Signin otpform" method="post" onSubmit={handleSubmit}>
      {/* <p style={{ marginBottom: '5px', color: 'black' }}>
        Enter four digit OTP
      </p> */}
      <div className="form-control">
      {error ? <p className="error">Invalid otp</p> : null}
        <input
          style={{ marginBottom: '40px' }}
          type="text"
          id="otp"
          name="otp"
          onChange={({ target }) => setOtp(target.value)}
          value={otp}
          placeholder="enter 4 digit OTP"
        />
      </div>
      <div className="form-control">
        <button type="submit" className="form__otp">
          Sign In{' '}
        </button>
      </div>
      
    </form>
  );
}

export default SignInOtp;
