import React from 'react';
import { Link } from 'react-router-dom';
import '../SignUp/SignUpSuccess/SignUpSuccess.css';
import dateFormat from "dateformat";

const ActivationSuccessUser = () => {

    let expiryDate = localStorage.getItem("expiry-date")
    let expiryDateFormated = dateFormat(expiryDate, "mmmm d, yyyy")

  return (
    <div className="signUp__success">
      <div className="signUp__sign">
        {/* <img src={Success} alt="success-sign" /> */}
      </div>
      <div className="signUp__text">
      <p>Congratulations! </p>
        <h2>Your getmetag is successfully activated and valid till : {expiryDateFormated}</h2>
        <h3>Now it's all ready. If someone scans your getmetag, we'll let you know</h3>
      </div>
      <Link to="/" className="SignUp__link">
        <div className="signUp__done">Proceed</div>
      </Link>
    </div>
  );
};

export default ActivationSuccessUser;
