import React, { useState } from 'react'
import axios from 'axios'
import './RaiseIssueOtp.css'
import { useHistory,Link } from 'react-router-dom'
import configData from '../../../config/config.json';


function RaiseIssueOtp({ messageid }) {
  const history = useHistory()
  const [otp, setOtp] = useState('')
  const [error, setError] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const headers = {
      'Content-type': 'application/json',
    }
    await axios(
      {
        method: 'post',
        url: `${configData.BASE_URL}/messages/${messageid}/confirm_otp`,
        data: {
          otp: otp,
          trigger_phone_call: true
        },
      },

      { headers }
    )
      .then((res) => {
        console.log('RESPOSE FROM CREATE ISSUE PAGE OTP', res)
        history.push('/raise-issue-success')
      })
      .catch((err) => {
        console.log(err)
        setError(true)
        setOtp('')
      })
  }

  return (
    <form className='otpform' method='post' onSubmit={handleSubmit}>
      <div className='form-control'>
        {error ? <p className='error'>invalid otp</p> : null}
        <input
          style={{ marginBottom: '20px' }}
          type='text'
          id='otp'
          name='otp'
          onChange={(e) => setOtp(e.target.value)}
          value={otp}
          placeholder='enter 4 digit OTP'
        />
      </div>
      <p
        style={{
          marginBottom: '12px',
          color: '#8c91a8',
          textAlign: 'center',
          fontSize: '12px',
        }}
      >
        By clicking 'Call Owner',<br></br> you accept our{' '}
        <span style={{ color: '#004AFF', cursor: 'pointer' }}>
          {' '}
          <Link to='/privacy-policy' className="policy__link">
          T&amp;C &amp; Privacy policy
          </Link>
        </span>
      </p>

      <div className='form-control'>
        <button type='submit' className='form__otp'>
          Call Owner{' '}
        </button>
      </div>
    </form>
  )
}

export default RaiseIssueOtp
