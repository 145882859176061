import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import './SignUp.css'
import OtpForm from './OtpForm/OtpForm'
import configData from '../../config/config.json'
import Footer from '../../Components/Footer'

function SignUp() {
  const [otpForm, setOtpForm] = useState(false)
  const [error, setError] = useState(false)
  const initialValues = {
    name: '',
    emailorphone: '',
  }

  const onSubmit = async (values) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    await axios(
      {
        method: 'post',
        url: `${configData.BASE_URL}/users/sign_up`,
        data: {
          login: values.emailorphone,
          user_details: {
            first_name: values.name,
          },
        },
      },
      { headers }
    )
      .then((res) => {
        console.log(res)
        setOtpForm(true)
      })
      .catch((err) => {
        console.log(err)
        setError(true)
        setOtpForm(false)
      })
  }

  const validate = (values) => {
    let errors = {}
    if (!values.name) {
      errors.name = 'Name required'
    }
    if (!values.emailorphone) {
      errors.emailorphone = 'Phone No. required'
    }
    // else if (!/\S+@\S+\.\S+/.test(values.email)) {
    //   errors.emailorphone = 'Email is invalid'
    // } else if (values.emailorphone.length !== 10) {
    //   console.log(values.emailorphone.length)
    //   errors.emailorphone = 'Phone number invalid'
    // }
    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })
  const isInvalid =
    formik.values.name === '' || formik.values.emailorphone === ''

  useEffect(() => {
    document.title = 'Sign up - Getmetag'
  }, [])
  // console.log(formik.values);
  return (
    <div className='signUp'>
      {/* section1 */}
      <nav className='signUp__nav'>
        <Link to='/' className='signUp__link'>
          <ChevronLeftIcon className='signUp__icon' /> Sign Up
        </Link>
      </nav>
      {/* section2 */}
      <div className='container'>
        <p className='text'>
          Signing up will help you create your getmetag. <br></br>{' '}
          <Link to='/signin' className='signIn__link'>
            Sign In
          </Link>{' '}
          if you already have an account
        </p>
        <form
          action=''
          method='post'
          className='signUp__form'
          onSubmit={formik.handleSubmit}
        >
          <div className='form-control'>
            {error && <p className='error'>User already exist</p>}
            <input
              type='text'
              id='name'
              name='name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder='enter your username'
            />
            {formik.touched.name && formik.errors.name ? (
              <div className='error'>{formik.errors.name}</div>
            ) : null}
          </div>
          <div className='form-control'>
            <input
              type='text'
              id='emailorphone'
              name='emailorphone'
              onChange={formik.handleChange}
              value={formik.values.emailorphone}
              onBlur={formik.handleBlur}
              placeholder='enter your phone no.'
            />
            {formik.touched.emailorphone && formik.errors.emailorphone ? (
              <div className='error'>{formik.errors.emailorphone}</div>
            ) : null}
          </div>
          <div className='form-control'>
            {otpForm ? null : (
              <button
                type='submit'
                className='form__otp'
                style={{ visibility: isInvalid ? 'hidden' : 'visible' }}
              >
                Get OTP{' '}
              </button>
            )}
          </div>
        </form>
        {otpForm ? <OtpForm email={formik.values.emailorphone} /> : null}
        {/* {otpForm ? <OtpForm email={formik.values.email} /> : <OtpForm email={formik.values.email} />} */}
      </div>
      <Footer/>
    </div>
  )
}

export default SignUp
